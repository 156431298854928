import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Masonry from 'react-masonry-css'

import { TaleType, TextTalePreviewData } from '../types';
import { TalePreview } from './TalePreview';

export interface TaleProps extends Pick<TextTalePreviewData, 'slug' | 'name' | 'description'> {
    type: TaleType
}

export interface TalesListProps {
    title?: string;
    list: TaleProps[];
}

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 30,
        marginBottom: 40,
        [theme.breakpoints.down('sm')]: {
            fontSize: 22,
            marginBottom: 20,
        }
    },
    grid: {
        display: 'flex',
        width: '100%',
    },
    column: {
        backgroundClip: 'padding-box',
        boxSizing: 'content-box',
        '& > div': {
            marginBottom: 30,
        },
        '&:not(:first-of-type)': {
            paddingLeft: 30,
        }
    }
}));

export const TalesList = ({ list, title }: TalesListProps) => {
    const classes = useStyles();

    return (
        <>
            {title &&
                <Typography variant="h1" gutterBottom className={classes.title}>
                    {title}
                </Typography>
            }
            <Grid container spacing={4}>
                <Masonry
                    breakpointCols={{
                        default: 3,
                        700: 2,
                    }}
                    className={classes.grid}
                    columnClassName={classes.column}>
                    {list.map((tale) => (
                        <React.Fragment key={tale.slug}>
                            {tale.type === 'text' ? (
                                <TalePreview
                                    key={tale.slug}
                                    link={`/text-tales/${tale.slug}`}
                                    imgSrc={`/images/${tale.slug}/main.png`}
                                    name={tale.name}
                                    description={tale.description}
                                />
                            ) : (
                                <TalePreview
                                    key={tale.slug}
                                    link={`/images-tales/${tale.slug}`}
                                    imgSrc={`${process.env.GATSBY_BACKEND_URL}/images-tales/${tale.slug}/0.jpg`}
                                    name={tale.name}
                                    description={tale.description}
                                />
                            )}
                        </React.Fragment>
                    ))}
                </Masonry>

            </Grid>
        </>
    );
}
