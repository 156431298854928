import React, { FC } from 'react';
import { Link } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';

interface PaginationProps {
    currentPage: number;
    pagesAmount: number;
    link: string;
}

const useStyles = makeStyles((theme) => ({
    pagination: {
        padding: '10px 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    link: {
        fontSize: 18
    },
    next: {
        marginLeft: 'auto',
    },
}))

export const Pagination: FC<PaginationProps> = ({ currentPage, pagesAmount, link }) => {
    const classes = useStyles();

    return (
        <div className={classes.pagination}>
            {currentPage > 1 && <Link className={classes.link} to={`${link}/${currentPage - 1 === 1 ? '' : currentPage - 1}`}>Назад</Link>}
            {currentPage < pagesAmount && <Link className={`${classes.next} ${classes.link}`} to={`${link}/${currentPage + 1}`}>Дальше</Link>}
        </div>
    );
}
