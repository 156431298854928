import React, { FC } from 'react';
import { graphql, PageProps } from 'gatsby';

import { TextTalePreviewData } from '../types';
import Layout from '../components/Layout';
import { TalesList } from '../components/TalesList'
import { Pagination } from '../components/Pagination';
import { getFieldsForTalePreview } from '../helpers/getFieldsForTalePreview';


interface TalesListTemplateData {
    allTextTales: {
        nodes: TextTalePreviewData[];
    }
}


export interface PageContextProps {
    currentPage: number;
    limit: number;
    numPages: number;
    skip: number;
}

const TextTalesListTemplate: FC<PageProps<TalesListTemplateData, PageContextProps>> = ({ location, data, pageContext: {currentPage, numPages} }) => {
    const { allTextTales } = data;

    const textTalesList = allTextTales.nodes.map(getFieldsForTalePreview('text'))

    return (
        <Layout location={location} seoTitle='Список текстовых сказок'>
            <TalesList title='Все сказки' list={textTalesList} />
            <Pagination currentPage={currentPage} pagesAmount={numPages} link='/text-tales/all' />
        </Layout>
    )
}

export default TextTalesListTemplate

export const query = graphql`
    query($skip: Int!, $limit: Int!) {
        allTextTales(limit: $limit, skip: $skip, sort: {order: ASC, fields: name}) {
            nodes {
              _id
              slug
              name
              description
              timeToRead
              author_id
            }
        }
    }
`
