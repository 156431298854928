import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Link } from 'gatsby';

const useStyles = makeStyles((theme) => ({
    card: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        transition: '.4s',
        overflow: 'hidden',
        '&:hover': {
            boxShadow: '5px 5px 10px #aaa',
            [theme.breakpoints.up('md')]: {
                '& $description': {
                    top: 0,
                }
            }
        }
    },
    cardDetails: {
        flex: 1,
    },
    description: {
        display: 'block',
        position: 'absolute',
        top: '100%',
        padding: 10,
        boxSizing: 'border-box',
        height: '100%',
        width: '100%',
        background: '#fff',
        overflow: 'hidden',
        transition: '.4s',
        fontSize: 18,
    },
    cardMedia: {
        width: '100%',
        backgroundSize: 'contain',
    },
    link: {
        textDecoration: 'none'
    },
    shadow: {
        display: 'block',
        boxShadow: '0 0 20px 10px #aaa',
        width: '100%',
        position: 'absolute',
        bottom: 0,
        left: 0,
    },
    taleName: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 16
        }
    },
    cardDContent: {
        [theme.breakpoints.down('xs')]: {
            padding: '10px!important'
        }
    }
}));

export interface TalePreviewProps {
    link: string;
    name: string;
    imgSrc: string;
    description?: string;
    timeToRead?: string;
}

export const TalePreview = ({imgSrc, link, name, description, timeToRead}: TalePreviewProps) => {
    const classes = useStyles();

    return (
        <Grid item xs={12}>
            <Link to={link} className={classes.link}>
                <Card className={classes.card}>
                    <img className={classes.cardMedia} src={imgSrc} alt={name} />
                    <div className={classes.cardDetails}>
                        <CardContent  className={classes.cardDContent}>
                            <Typography component="h2" variant="h6" className={classes.taleName}>
                                {name}
                            </Typography>
                            <Typography variant="subtitle1" color="textSecondary">
                                {timeToRead}
                            </Typography>
                        </CardContent>
                    </div>
                    {/*{!!description && (*/}
                    {/*    <div className={classes.description}>*/}
                    {/*        {description}*/}
                    {/*        <span className={classes.shadow} />*/}
                    {/*    </div>*/}
                    {/*)}*/}
                </Card>
            </Link>
        </Grid>
    );
}
